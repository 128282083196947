:root {
    /** colors **/
    --white: #fff;
    --orange: #F2994A;
    --orange100: #ED8F3B;
    --grey100: #F5F5F5;
    --grey200: #EEEEEE;
    --grey300: #E0E0E0;
    --grey400: #BDBDBD;
    --grey500: #F0F0F0;
    --grey600: #757575;
    --grey700: #616161;
    --grey900: #212121;

    --redA700: #DD2C00;
    --red100: #FFCCBC;

    --blue: #57AEFF;
    --bluey: #57AEFF;
    --blueA700: #2962FF;

    --teal1: #9BE9EE;
    --teal4: #0F565B;

    /** z-index **/
    --z-index-mui-dialog: 3006;
    --z-index-dialog: 3004;
    --z-index-dialog-backdrop: 3003;
    --z-index-profile-menu: 3002;
    --z-index-overlay: 3001;
    --z-index-nav-bar: 3000;
    --z-index-filter-options: 2999;
}
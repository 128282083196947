@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap');
@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.0.0/dist/css/bootstrap.min.css');
@import url('./vars.module.css');

* {
  font-family: 'Noto Sans JP', sans-serif;
}

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
}

div#__next {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.star-ratings {
  height: 24px;
  display: flex !important;
}

.star-container {
  display: flex !important;
}

.DayPicker-Day {
  width: 40px;
}

*::-webkit-scrollbar {
  /*background: var(--grey100);*/
  width: 4px;
}

*::-webkit-scrollbar-thumb {
  background: #3C7F86;
  border-radius: 10px;
  box-shadow: inset 0 0 6px #3C7F86;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px var(--grey100);
  border-radius: 10px;
}